<template>
  <div class="full-page">

    <!-- COHORT SELECT AND EDIT -->
    <div class="cohort-container">
      <h2>Cohorts</h2>
      <!-- Drop down menu to select from cohorts based on cohorts[i].id -->

      <div class="input-row mb-3">
        <select class="form-select" style="width: 400px;" id="cohortSelect" v-model="selectedCohortId">
          <option value="null" selected>Select a cohort...</option>
          <option v-for="cohort in cohorts" :key="cohort.id" :value="cohort.id">{{cohort.id}} - {{cohort.name}}</option>
        </select> 
        <div class="input-group ms-3" style="width: 300px;">
          <button class="input-group-text" style="width: 75px;">ID</button>
          <input type="text" class="form-control" placeholder="Enter in the course code..." id="cohortID" v-model="cohortID">    
        </div>
        <div class="input-group ms-3" style="width: 300px;">
          <button class="input-group-text" style="width: 75px;">Name</button>
          <input type="text" class="form-control" placeholder="Name this cohort..." id="cohortName" v-model="cohortName">    
        </div>
      </div>
      <div class="input-group mb-3" style="width: 75%; height: 100px;">
        <button class="input-group-text" style="width: 100px;">Emails</button>
        <textarea type="text" class="form-control" placeholder="Enter in emails of students seperated by a comma..." id="cohortEmails" v-model="cohortEmails"></textarea>    
      </div>
      <button class="btn btn-outline-secondary" type="button" id="editCohort" @click="editCohort">Create/Edit Cohort</button>
    </div>

    <!-- GRADEBOOK FOR SELECTED COHORT -->
    <div class="gradebook-container">
      <h2>Gradebook</h2>
      <p>Missing Students: {{ missingEmails }}</p>
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th v-for="header in statsGradebookHeaders">{{ header }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="student in gradebook" :key="student.id">
              <td v-for="header in statsGradebookHeaders" @click="openModal(header, student)">{{ createStudentGradebook(student)[header.toLowerCase()] }}</td>
            </tr>
              
          </tbody>
        </table>
        <GradeModal v-if="showModal" :student="selectedStudent" :header="selectedHeader" :grade="selectedGrade" @close="showModal = false" @update="updateGrade"/>
      </div>
    </div>

    <!-- RESET BUTTON -->
    <button class="btn btn-outline-secondary" style="margin-top: 20px;" type="button" id="resetButton" @click="resetCourse">Reset Course for Me</button>


  </div>



</template>

<script>
import GradeModal from '@/components/GradeModal.vue';
import { collection, getDocs, doc, getDoc, setDoc } from "firebase/firestore"; 
import { auth, database } from '@/main';
import { create, all } from 'mathjs'
const math = create(all);


export default {
  name: 'controlPanel',
  components: {
    GradeModal
  },
  data() {
    return {
      users: [],
      cohorts: [],
      gradebook: [],
      missingEmails: "",
      selectedCohortId: null,
      cohortID: '',
      cohortName: '',
      cohortEmails: '',
      statsGradebookHeaders: [
        'Email',
        'Total',
        'EC',
        'L1',
        'L2',
        'L3',
        'L4',
        'L5',
        'L6',
        'L7',
        'L8',
        'L9',
        'L10',
        'L11',
        'L12',
        'L13',
        'L14',
        'E0',
        'E1',
        'E2',
        'E3',
        'E4',
        'E5',
        'E6',
        'E7',
        'E8',
        'E9',
        'E10',
        'E11',
        'E12',
        'E13',
        'E14',
        'C1',
        'C2',
        'C3',
        'C4',
        'C5',
        'C6',
        'C7',
        'C8',
        'C9',
        'C10',
        'C11',
        'C12',
        'C13',
        'C14',
      ],
      showModal: false,
      selectedStudent: '',
      selectedHeader: '',
      selectedGrade: '',
    };
  },
  async created() {
    const usersRef = collection(database, 'users');
    const snapshot = await getDocs(usersRef);
    snapshot.forEach(doc => {
        let user = doc.data();
        user.id = doc.id;
        this.users.push(user);
      });
    this.cohorts = await this.getCohorts();
    console.log(this.users, this.cohorts);
  },
  watch: {
    selectedCohortId(newVal) {
      this.updateCohortDetails(newVal);
    }
  },
  methods: {
    async getCohorts() {
      const docRef = doc(database, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      let data = docSnap.data();
      return data.cohorts;
    },
    updateCohortDetails(cohortId) {
      const selectedCohort = this.cohorts.find(cohort => cohort.id === cohortId);
      if (selectedCohort) {
        this.cohortID = selectedCohort.id;
        this.cohortName = selectedCohort.name;
        this.cohortEmails = selectedCohort.emails.toLowerCase(); // Assuming emails is an array
        let newGradebook = [];
        this.users.forEach(user => {
          if (selectedCohort.emails.includes(user.userInfo.email)) {
            newGradebook.push(user);
          }
        });
        console.log(newGradebook);
        this.missingEmails = selectedCohort.emails.split(", ").filter(email => !newGradebook.map(user => user.userInfo.email.toLowerCase()).includes(email.toLowerCase())).join(", ");
        this.gradebook = newGradebook;
      } else {
        this.cohortID = '';
        this.cohortName = '';
        this.cohortEmails = '';
      }
    },
    async editCohort() {
      // Check to make sure all fields are filled out and give ui alert if not
      if (!this.cohortID || !this.cohortName || !this.cohortEmails) {
        alert('Please fill out all fields');
        return;
      }
      // Set Create/Edit Cohort button to to a spinning icon
      document.getElementById('editCohort').innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Updating...';
      // Add logic to create or edit cohort
      const docRef = doc(database, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      let data = docSnap.data();
      // check if cohort already exists
      let cohortIndex = data.cohorts.findIndex(cohort => cohort.id === this.cohortID);
      if (cohortIndex === -1) {
        // Create new cohort
        data.cohorts.push({
          id: this.cohortID,
          name: this.cohortName,
          emails: this.cohortEmails,
        });
      } else {
        // Edit existing cohort
        data.cohorts[cohortIndex] = {
          id: this.cohortID,
          name: this.cohortName,
          emails: this.cohortEmails,
        };
      }
      await setDoc(docRef, data);
      this.cohorts = await this.getCohorts();
      document.getElementById('editCohort').innerHTML = 'Create/Edit Cohort';
    },
    createStudentGradebook(student) {
      return {
        id: student.id,
        email: student.userInfo.email,
        total: this.totalGrade(student),
        ec: student.stats.gradeBook.ch0.ec,
        l1: this.learnGrade(student.stats.gradeBook.ch1),
        l2: this.learnGrade(student.stats.gradeBook.ch2),
        l3: this.learnGrade(student.stats.gradeBook.ch3),
        l4: this.learnGrade(student.stats.gradeBook.ch4),
        l5: this.learnGrade(student.stats.gradeBook.ch5),
        l6: this.learnGrade(student.stats.gradeBook.ch6),
        l7: this.learnGrade(student.stats.gradeBook.ch7),
        l8: this.learnGrade(student.stats.gradeBook.ch8),
        l9: this.learnGrade(student.stats.gradeBook.ch9),
        l10: this.learnGrade(student.stats.gradeBook.ch10),
        l11: this.learnGrade(student.stats.gradeBook.ch11),
        l12: this.learnGrade(student.stats.gradeBook.ch12),
        l13: this.learnGrade(student.stats.gradeBook.ch13),
        l14: this.learnGrade(student.stats.gradeBook.ch14),
        e0: student.stats.gradeBook.ch0.lesson,
        e1: student.stats.gradeBook.ch1.lesson,
        e2: student.stats.gradeBook.ch2.lesson,
        e3: student.stats.gradeBook.ch3.lesson,
        e4: student.stats.gradeBook.ch4.lesson,
        e5: student.stats.gradeBook.ch5.lesson,
        e6: student.stats.gradeBook.ch6.lesson,
        e7: student.stats.gradeBook.ch7.lesson,
        e8: student.stats.gradeBook.ch8.lesson,
        e9: student.stats.gradeBook.ch9.lesson,
        e10: student.stats.gradeBook.ch10.lesson,
        e11: student.stats.gradeBook.ch11.lesson,
        e12: student.stats.gradeBook.ch12.lesson,
        e13: student.stats.gradeBook.ch13.lesson,
        e14: student.stats.gradeBook.ch14.lesson,
        c1: student.stats.gradeBook.ch1.assignment,
        c2: student.stats.gradeBook.ch2.assignment,
        c3: student.stats.gradeBook.ch3.assignment,
        c4: student.stats.gradeBook.ch4.assignment,
        c5: student.stats.gradeBook.ch5.assignment,
        c6: student.stats.gradeBook.ch6.assignment,
        c7: student.stats.gradeBook.ch7.assignment,
        c8: student.stats.gradeBook.ch8.assignment,
        c9: student.stats.gradeBook.ch9.assignment,
        c10: student.stats.gradeBook.ch10.assignment,
        c11: student.stats.gradeBook.ch11.assignment,
        c12: student.stats.gradeBook.ch12.assignment,
        c13: student.stats.gradeBook.ch13.assignment,
        c14: student.stats.gradeBook.ch14.assignment,
      }
    },
    totalGrade(user) {
      let total = 0;
      for (let ch in user.stats.gradeBook) {
        if(user.stats.gradeBook[ch].ec) total += Number(user.stats.gradeBook[ch].ec);
        if(user.stats.gradeBook[ch].lesson) total += Number(user.stats.gradeBook[ch].lesson);
        if(user.stats.gradeBook[ch].assignment) total += Number(user.stats.gradeBook[ch].assignment);
        if(user.stats.gradeBook[ch].mod1) total += math.sum(user.stats.gradeBook[ch].mod1);
        if(user.stats.gradeBook[ch].mod2) total += math.sum(user.stats.gradeBook[ch].mod2);
        if(user.stats.gradeBook[ch].mod3) total += math.sum(user.stats.gradeBook[ch].mod3);
        if(user.stats.gradeBook[ch].mod4) total += math.sum(user.stats.gradeBook[ch].mod4);
        if(user.stats.gradeBook[ch].mod5) total += math.sum(user.stats.gradeBook[ch].mod5);
        if(user.stats.gradeBook[ch].mod6) total += math.sum(user.stats.gradeBook[ch].mod6);
        if(user.stats.gradeBook[ch].mod7) total += math.sum(user.stats.gradeBook[ch].mod7);
        if(user.stats.gradeBook[ch].mod8) total += math.sum(user.stats.gradeBook[ch].mod8);
        if(user.stats.gradeBook[ch].mod9) total += math.sum(user.stats.gradeBook[ch].mod9);
        if(user.stats.gradeBook[ch].mod10) total += math.sum(user.stats.gradeBook[ch].mod10);
      }
      return total;
    },
    learnGrade(user) {
      let total = 0;
      if(user.mod1) total += math.sum(user.mod1);
      if(user.mod2) total += math.sum(user.mod2);
      if(user.mod3) total += math.sum(user.mod3);
      if(user.mod4) total += math.sum(user.mod4);
      if(user.mod5) total += math.sum(user.mod5);
      if(user.mod6) total += math.sum(user.mod6);
      if(user.mod7) total += math.sum(user.mod7);
      if(user.mod8) total += math.sum(user.mod8);
      if(user.mod9) total += math.sum(user.mod9);
      if(user.mod10) total += math.sum(user.mod10);
      return total;
    },
    openModal(header, student) {
      this.selectedStudent = student;
      this.selectedHeader = header;
      this.selectedGrade = this.createStudentGradebook(student)[header.toLowerCase()];
      if (header === 'Email') return;
      if (header === 'Total') return;
      if (header === 'EC') {
        this.showModal = true;
      };
    },
    async updateGrade(payload) {
      console.log(`Updated grade for ${payload.id} in ${payload.header}: ${payload.grade}`);
      this.showModal = false;

      if(payload.header === 'EC') {
        this.selectedStudent.stats.gradeBook.ch0.ec = payload.grade;
        const docRef = doc(database, "users", payload.id);
        await setDoc(docRef, {
          stats: {
            gradeBook: {
              ch0: {
                ec: Number(payload.grade),
              }
            }
          }
        }, {merge: true});
      }
    },
    async resetCourse() {
      console.log('Resetting course for me: ', auth.currentUser.uid);
      const docRef = doc(database, "users", auth.currentUser.uid);
      await setDoc(docRef, {
        stats: {
        }
      }, {merge: true});
    },
  },
};
</script>

<style scoped>
.full-page {
  margin: 24px;
  display: block;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.cohort-container {
  display: flex;
  flex-direction: column;
  background-color: #ccc;
  padding: 12px;
  border-radius: 15px;
  margin-bottom: 24px;
}
.input-row {
  display: flex;
  flex-direction: row;
}
.gradebook-container {
  display: flex;
  flex-direction: column;
  background-color: #ccc;
  padding: 12px;
  border-radius: 15px;
}
</style>