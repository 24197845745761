<template>
<div class="container login-container">
    <div class="login-div rounded shadow ">
        <div class="logo-holder">
            <img src="../assets/ProGoLoGo.png" alt="ProGoLoGo" class="img-fluid" style="width: 300px; margin: 0 auto; display: block;">
        </div>
        
            
        <div class="fw700 em2">Login</div>

            <div class="input-group">
                <label for="email">Email:</label>
                <input type="email" id="email" name="email" autocomplete="email" required>
            </div>
            <div class="input-group">
                <label for="password">Password:</label>
                <input type="password" id="password" name="password" autocomplete="current-password" required>
            </div>
            <button class="login-button" @click="loginEmailPassword">Login</button>
            <br><br>
            <div>Don't have an account? <router-link to="/register">Register here</router-link></div>
            <div><router-link to="/password-reset">Forgot your password?</router-link></div>

    </div>
</div>
</template>

<script>
import { auth } from '../main';
import { signInWithEmailAndPassword } from 'firebase/auth';
import ProGoLoGo from '../assets/ProGoLoGo.png';


export default {
  name: "loginPage",
  data() {
    return {
        ProGoLoGo,
    }
  },
  methods: {
    async loginEmailPassword() {
        const loginEmail = document.getElementById('email').value;
        const loginPassword = document.getElementById('password').value;
        try {
            await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
            const redirect = this.$route.query.redirect || '/stats'; 
            this.$router.push(redirect);
        } catch (e) {
            console.error("Authentication failed:", e);
        }
    },
  }
}
</script>

<style scoped>
.logo-holder {
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    background: linear-gradient(90deg in oklab, blue, red);
}
.login-container { 
    display: flex;
    justify-content: center;
    align-items: center;
}
.input-group {
    margin-bottom: 20px;
}
.input-group label {
    display: block;
    margin-bottom: 5px;
}
.input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.login-button {
    width: 100%;
    padding: 10px;
    background: linear-gradient(90deg in oklab, blue, red);
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.login-button:hover {
    background: linear-gradient(270deg in oklab, blue, red);
}
.login-div {
    left: auto;
    margin-top: 100px;
    padding: 24px;
    width: 400px;
    height: 500px;
    background-color: var(--off_white);
}
</style>